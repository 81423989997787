import "core-js/modules/es.array.push.js";
import userSettingsService from '../../services/settings/userSettingsService';
import CalUserSettingsEntry from './CalUserSettingsEntry.vue';
import contextMenuService from '@/services/settings/contextMenuService.js';
import { UserSettingClass } from '@/models/settings/UserSetting.js';
export default {
  data: () => ({
    contextMenuService,
    userSettingsService,
    typeOfCalendarOverview: '',
    typeOfCalendarOverviewItems: ['5-Tage-Woche', '7-Tage-Woche', 'Geteilte Ansicht: Bereiche', 'Geteilte Ansicht: Lokationen', 'Monatsansicht', 'Tagesansicht'],
    zoom: '',
    zoomItems: ['20%', '40%', '60%', '80%', '100%', '120%', '140%', '160%', '180%', '200%'],
    eventTitleItems: ['Anfang und Ende', 'Beschreibung', 'Dauer', 'Erster Kundenname', 'Lokation', 'Status', 'Terminart', 'Terminbereich'],
    contextMenu: [],
    contextMenuItems: [{
      name: 'Von',
      id: 0
    }, {
      name: 'Bis',
      id: 1
    }, {
      name: 'Betreff',
      id: 2
    }, {
      name: 'Status',
      id: 3
    }, {
      name: 'Lokation',
      id: 4
    }, {
      name: 'Bereich',
      id: 5
    }, {
      name: 'Terminart',
      id: 6
    }, {
      name: 'Erster Kundenname',
      id: 7
    }, {
      name: 'Tel.Nr.',
      id: 8
    }],
    collisionDetection: '',
    contextMenuDuration: '',
    eventTitle: ''
  }),
  mounted() {
    console.warn(this.settings);
    this.zoom = Math.round(this.value('zoom') / 50 * 100) + '%';
    this.typeOfCalendarOverview = this.getTypeOfCalendarOverview();
    this.contextMenu = this.value('contextMenu');
    this.eventTitle = this.getEventTitle();
    this.collisionDetection = this.value('collisionDetection') === 'true';
    this.contextMenuDuration = this.value('contextMenuDuration');
  },
  components: {
    CalUserSettingsEntry
  },
  computed: {},
  props: {
    userId: {
      type: String,
      required: true
    },
    userLvl: {
      type: Number,
      required: true
    },
    settings: {
      type: Array,
      required: true
    }
  },
  methods: {
    getTypeOfCalendarOverview() {
      var value = this.value('typeOfCalendarOverview');
      switch (value) {
        case '5week':
          return '5-Tage-Woche';
        case '7week':
          return '7-Tage-Woche';
        case 'day':
          return 'Tagesansicht';
        case 'month':
          return 'Monatsansicht';
        case 'location':
          return 'Geteilte Ansicht: Lokationen';
        case 'scope':
          return 'Geteilte Ansicht: Bereiche';
      }
      return value;
    },
    getEventTitle() {
      var value = this.value('eventTitle');
      switch (value) {
        case 'scope':
          return 'Terminbereich';
        case 'duration':
          return 'Dauer';
        case 'beginAndEnd':
          return 'Anfang und Ende';
        case 'title':
          return 'Beschreibung';
        case 'location':
          return 'Lokation';
        case 'address':
          return 'Erster Kundenname';
        case 'type':
          return 'Terminart';
        case 'state':
          return 'Status';
      }
      return value;
    },
    setTypeOfCalendarOverview(value) {
      switch (value) {
        case '5-Tage-Woche':
          return '5week';
        case '7-Tage-Woche':
          return '7week';
        case 'Tagesansicht':
          return 'day';
        case 'Monatsansicht':
          return 'month';
        case 'Geteilte Ansicht: Lokationen':
          return 'location';
        case 'Geteilte Ansicht: Bereiche':
          return 'scope';
      }
      return value;
    },
    setEventTitle(value) {
      switch (value) {
        case 'Terminbereich':
          return 'scope';
        case 'Dauer':
          return 'duration';
        case 'Anfang und Ende':
          return 'beginAndEnd';
        case 'Beschreibung':
          return 'title';
        case 'Lokation':
          return 'location';
        case 'Erster Kundenname':
          return 'address';
        case 'Terminart':
          return 'type';
        case 'Status':
          return 'state';
      }
      return value;
    },
    value(name) {
      console.warn('VALUE()');
      console.warn(this.settings);
      const foundValue = this.settings.find(s => s.valueName === name);
      if (foundValue !== undefined) {
        return foundValue.value;
      } else {
        console.error('Value not found:', name);
        return '0';
      }
    },
    changedZoom(value) {
      this.zoom = value;
    },
    changedTypeOfCalendarOverview(value) {
      this.typeOfCalendarOverview = value;
    },
    changedCollisionDetection(value) {
      this.collisionDetection = value;
    },
    changedContextMenu(value) {
      this.contextMenu = contextMenuService.convertGermanNamesToDBString(value);
    },
    changedContextMenuDuration(value) {
      this.contextMenuDuration = value;
    },
    changedEventTitle(value) {
      this.eventTitle = value;
    },
    save() {
      var toco = this.setTypeOfCalendarOverview(this.typeOfCalendarOverview);
      var et = this.setEventTitle(this.eventTitle);
      var userSettings = [];
      var zoomValue = 0;
      if (this.zoom.endsWith('%')) {
        zoomValue = parseInt(this.zoom.slice(0, -1)) / 100 * 50;
      }
      userSettings.push(new UserSettingClass('', 'WEB_cal', 'zoom', zoomValue + ''));
      userSettings.push(new UserSettingClass('', 'WEB_cal', 'typeOfCalendarOverview', toco));
      userSettings.push(new UserSettingClass('', 'WEB_cal', 'collisionDetection', this.collisionDetection + ''));
      userSettings.push(new UserSettingClass('', 'WEB_cal', 'contextMenu', this.contextMenu));
      userSettings.push(new UserSettingClass('', 'WEB_cal', 'eventTitle', et));
      userSettings.push(new UserSettingClass('', 'WEB_cal', 'contextMenuDuration', this.contextMenuDuration));
      console.warn(this.userId);
      userSettingsService.saveUserSettings(this.userId, userSettings).then(() => {
        this.$toast.success('Benutzereinstellungen gespeichert!');
        this.$store.dispatch('loadUserSettings');
      });
    }
  }
};