/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./CalUserSettings.vue?vue&type=template&id=904e2d58"
import script from "./CalUserSettings.vue?vue&type=script&lang=js"
export * from "./CalUserSettings.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports