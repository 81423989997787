import "core-js/modules/es.array.push.js";
import WarningDialog from '@/components/core/WarningDialog.vue';
import masterDataService from '@/services/settings/masterDataService.js';
export default {
  data: () => ({
    table: false,
    type: '',
    headers: [],
    keyValue: '',
    items: [],
    editedItem: {},
    menu: false,
    search: '',
    isNew: false,
    actionMode: 'undefined'
  }),
  props: {
    category: {
      type: String,
      required: true
    }
  },
  components: {
    WarningDialog
  },
  computed: {
    color: {
      get() {
        return this.editedItem.color;
      },
      set(newColor) {
        return newColor;
      }
    },
    swatchStyle() {
      const {
        menu,
        color
      } = this;
      return {
        backgroundColor: color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: menu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out'
      };
    }
  },
  methods: {
    testus(item) {
      console.log(item);
    },
    saveNewItem() {
      console.log('saveNEw');
      var i = this.items.findIndex(item => item[this.keyValue] = this.getNestedPropertyValue(this.editedItem, this.keyValue));
      this.items.splice(i, 1);
      this.items.unshift(this.editedItem);
      this.initializeDefaultValues();
      if (this.isObjectKey) {
        delete this.editedItem.lfdnr;
      }
      masterDataService.saveMasterData(this.category, this.editedItem).then(() => {
        this.$toast.success('Datensatz wurde erstellt!');
        this.$emit('reload');
      });
      this.isNew = false;
    },
    initializeDefaultValues() {
      var newLfdnr = Math.max(...this.items.map(i => this.getNestedPropertyValue(i, this.keyValue))) + 1;
      if (this.items.length != 0) {
        var defaultItem = JSON.parse(JSON.stringify(this.items[0]));
        this.setNestedProperty(defaultItem, this.keyValue, newLfdnr);
        this.editedItem = Object.assign({}, defaultItem);
      }
    },
    isObjectKey() {
      var keyValue = this.keyValue;
      if (keyValue == 'id' || keyValue == 'lfdnr') {
        return false;
      }
      return true;
    },
    getIncrementedId() {
      let highest = 1;
      this.items.forEach(item => {
        if (item.lfdnr > highest) {
          highest = item.lfdnr;
        }
      });
      return highest + 1;
    },
    focusOnSecondClass(className) {
      const cells = this.$el.querySelectorAll(`.${className}`);
      if (cells.length >= 2) {
        const secondCell = cells[1]; // Accessing the second occurrence
        const input = secondCell.querySelector('input');
        if (input) {
          input.focus();
        }
      }
    },
    addItem() {
      this.actionMode = 'add';
      this.unAssignEditedItem();
      var newItem = Object.assign({}, this.editedItem);
      newItem.lfdnr = this.getIncrementedId();
      this.items.unshift(newItem);
      this.editItem(newItem);
      this.isNew = true;
    },
    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.color = item.color;
      setTimeout(() => {
        this.focusOnSecondClass('v-text-field__slot');
      }, 1);
    },
    editExistingItem(item) {
      this.actionMode = 'edit';
      console.log(item);
      this.editItem(item);
    },
    unAssignEditedItem() {
      if (this.isNew) {
        var i = this.items.findIndex(item => item[this.keyValue] === this.editedItem[this.keyValue]);
        this.items.splice(i, 1);
        this.isNew = false;
      }
      this.initializeDefaultValues();
    },
    checkSaveItem() {
      console.log('checkSaveItem');
      var actions = [{
        icon: 'mdi-content-save-all',
        text: 'Speichern',
        action: 'save'
      }, {
        icon: 'mdi-close',
        text: 'Abbrechen',
        action: 'cancel'
      }];
      this.$refs.warningSave.open('Wollen Sie den Datensatz bearbeiten?', actions, this.editedItem);
    },
    checkDeleteItem(item) {
      var actions = [{
        icon: 'mdi-delete',
        text: 'Löschen',
        action: 'delete'
      }, {
        icon: 'mdi-close',
        text: 'Abbrechen',
        action: 'cancel'
      }];
      this.$refs.warningDelete.open('Wollen Sie den Datensatz löschen?', actions, item);
    },
    warningActionPerformedSave(action) {
      if (action === 'save') {
        this.saveEditedItem(this.$refs.warningSave.additionalData);
      }
      this.unAssignEditedItem();
    },
    warningActionPerformedDelete(action) {
      if (action === 'delete') this.deleteItem(this.$refs.warningDelete.additionalData);
    },
    deleteItem(item) {
      masterDataService.deleteMasterData(this.category, item).then(() => {
        this.$toast.success('Datensatz wurde gelöscht!');
        this.$emit('reload');
      });
    },
    saveEditedItem() {
      /*var i = this.items.findIndex(
        item => (item.key.lfdnr = this.editedItem.key.lfdnr)
      )*/
      masterDataService.saveMasterData(this.category, this.editedItem).then(() => {
        this.$emit('reload');
        this.$toast.success('Datensatz wurde bearbeitet!');
      });
    },
    open(type, headers, items, clazz) {
      this.clazz = clazz;
      this.type = type;
      this.headers = headers;
      this.headers.forEach(element => {
        element.align = 'auto';
      });
      this.headers.forEach(header => {
        if (header.value == 'lfdnr' || header.value == 'key.lfdnr' || header.value == 'id') {
          this.keyValue = header.value;
        }
      });
      var actions = {
        text: 'Aktionen',
        value: 'actions',
        align: 'end'
      };
      this.headers.push(actions);
      this.items = items;
      this.initializeDefaultValues();
      this.table = true;
    },
    close() {
      this.table = false;
    },
    getNestedPropertyValue(object, path) {
      const pathSegments = path.split('.');
      let value = object;
      for (const segment of pathSegments) {
        if (value && segment in value) {
          value = value[segment];
        } else {
          value = null;
          break;
        }
      }
      return value;
    },
    setNestedProperty(obj, path, value) {
      var schema = obj;
      var pList = path.split('.');
      var len = pList.length;
      for (var i = 0; i < len - 1; i++) {
        var elem = pList[i];
        if (!schema[elem]) schema[elem] = {};
        schema = schema[elem];
      }
      schema[pList[len - 1]] = value;
      console.log();
    },
    isBoolean(header) {
      if (header.dataType === 'BOOLEAN') return true;
      return false;
    },
    isColor(header) {
      if (header.dataType === 'COLOR') return true;
      return false;
    },
    isArray(header) {
      if (header.dataType === 'ARRAY') return true;
      return false;
    },
    isANumber(header) {
      if (header.dataType === 'NUMBER') return true;
      return false;
    }
  }
};